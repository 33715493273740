.portfolio-nav {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
    background-color: var(--color-darker);
}

.portfolio-navbar-toggle {
    border-radius: 10px;
    border: 2px solid var(--color-darkest);
    background-color: var(--color-bright);
}

.navbar-toggler-icon {
    color: var(--color-bright);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}