/*
 * Globals
 */
:root {
  --color-bright: #00ffe1;
  --color-dark: #1e252f;
  --color-darker: #14191f;
  --color-darkest: #0a0c0f;
  --color-light: #e7fffc;
  --color-lighter: #f7ffff;
  --color-lightest: #ffffff;
  --color-accent: #ffd1bf;

  --bs-navbar-toggler-border-color: #00ffe1;
}


/*
 * Base structure
 */

body {
  font-family: "Poppins", sans-serif;
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  background-color: var(--color-dark);
}

html, body {
  height: 100%;
  width: 100%;
}

hr {
  color: var(--color-accent);
}

a {
  color: var(--color-bright);
  text-decoration: none;
}

.portfolio-secret {
  color: var(--color-bright);
  text-shadow: 0px 1px 1px #00ffe1;
  font-size: 0.5rem;
  line-height: 0.5rem;
  text-align: center;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
/*
 * Header
 */

.nav-masthead .nav-link {
  color: rgba(255, 255, 255, .5);
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

/* 
  * Custom Buttons
  */

.btn-portfolio {
  color: #000;
  background-color: #00ffe1;
}
.btn-portfolio:hover {
  color: #000;
  background-color: #ffd1bf;
}

/* 
  *
  * Custom Portfolio Nav
  *
  */

.navigation a {
  color: #e7fffc;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0 1rem; 
  border-bottom: .2rem solid transparent;
}
.navigation a:hover {
  color: #ffd1bf;
  border-bottom-color: #ffd1bf;
}

.portfolio-nav-text, .portfolio-nav-text a {
  color: #00ffe1;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.portfolio-nav-text a:hover {
  color: #ffd1bf!important;

}
 .portfolio-nav-text a:focus, .portfolio-nav-text a:active, .nav-link.active, .navbar-nav .nav-link.active {
  color: #00ffe1;
}


.name-header {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 3rem;
}

.down-arrow {
  font-size: 3rem;
  color: #00ffe1; 
  cursor: pointer;
}
.down-arrow:hover {
  color: #ffd1bf;
}

.portfolio-button-link {
  color: var(--color-bright);
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 2rem;
  letter-spacing: 2px;
  font-weight: 700;
}

.portfolio-button-link:hover {
  color: var(--color-accent);
}

.portfolio-button {
  color: #000;
  background-color: #00ffe1;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: 600;
}
.portfolio-button.secondary {
  color: #000;
  background-color: #a2a2a2;
}
.portfolio-button:hover {
  color: #000;
  background-color: #ffd1bf;
}
.portfolio-button.link {
  color: #00ffe1;
  background-color: transparent;
  border: none;
  font-size: 1rem;
  letter-spacing: 2px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .name-header {
    font-size: 2rem;
  }
  .down-arrow {
    font-size: 2.5rem;
  }
  .portfolio-button {
    font-size: .75rem;
  }
  .portfolio-nav-text {
    font-size: .75rem;
  }
}
  
