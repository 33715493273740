
.portfolio-bottom {
    width: 50px;
}
.up-arrow {
    font-size: 3rem;
    color: #a2a2a2; 
    cursor: pointer;
  }
  .up-arrow:hover {
    color: #ffd1bf;
  }

  @media screen and (max-width: 768px) {
    .portfolio-bottom {
      width: 25px;
    }
    .up-arrow {
        font-size: 1.5rem;
      }
  }