@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,600;1,400&family=Shadows+Into+Light&display=swap');

.portfolio-resume {
  background:linear-gradient(rgba(10, 12, 15, 0), var(--color-darker));
  background-color: var(--color-dark);

}
.portfolio-resume-container {
  margin-top: 75px;
}

.portfolio-resume-image {
  border: 1px solid var(--color-bright);
  border-radius: 10px;
}
.portfolio-resume-image:hover {
  border: 1px solid var(--color-accent);
}

.portfolio-resume-site {
  color: var(--color-accent);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 1.25rem;
  letter-spacing: 2px;
  font-variant: small-caps;
}

.portfolio-resume-hr {
  border: 1px solid var(--color-accent);
  width: 100%;
}

hr {
  color: var(--color-accent);
}

em {
  padding-top: 3px;
}
span.bull {
  color: var(--color-accent);
  font-weight: 600;
}

ul li::marker {
  color: var(--color-accent);
}

h4.small-caps {
  font-variant: small-caps;
}

div.info, h5, span {
  text-shadow: 0px 0px 5px #000;
}

@media screen and (max-width: 1400px) {
  h5 {
    font-size: 1.1rem;
    margin-bottom: 2px;
  }
}
@media screen and (max-width: 768px) {
  h5 {
    font-size: 1rem;
    margin-bottom: 2px;
  }
  em {
    font-size: 0.8rem;
  }
  
}