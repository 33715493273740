@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,600;1,400&family=Shadows+Into+Light&display=swap');

.portfolio-contact {
  background:linear-gradient( var(--color-darker), var(--color-dark));
  background-color: var(--color-dark);

}
.portfolio-contact-container {
  margin-top: 75px;
}

.portfolio-input {
  background-color: var(--color-darker);
  border: 1px solid var(--color-light);
  color: var(--color-accent);
  padding: 15px;
}
