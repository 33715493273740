@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,600;1,400&family=Shadows+Into+Light&display=swap');

.portfolio-projects {
  background-image: radial-gradient(circle, #1e252f, #14191f, rgb(10, 12, 15));
}
.portfolio-projects-container {
  margin-top: 75px;
}
.portfolio-projects-card-container {
  background-color: var(--color-dark);
  display: flex;
  justify-content:center;
  width: 30%;
  min-width: 300px;
  min-height: 400px;
}

.portfolio-projects-card {
  background-color: var(--color-dark);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0;
  border-radius: 5px;
}

.portfolio-projects-image {
  width: 200px;
  height: 200px;
  max-width: 250px;
  max-height: 250px;
}

