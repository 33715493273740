@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,600;1,400&family=Shadows+Into+Light&display=swap');

.portfolio-about {
  background:linear-gradient(rgba(10, 12, 15, 0), var(--color-darker));
  background-color: var(--color-dark);

}
.portfolio-about-container {
  margin-top: 75px;
}

.portfolio-about-image {
  width: 85vw;
  height: 85vw;
  max-width: 250px;
  max-height: 250px;
}


.portfolio-about-skills {
  color: var(--color-accent);
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
}

hr {
  color: var(--color-accent);
}